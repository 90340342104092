import { Form } from 'antd';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { editProfile } from 'redux/users/thunks';
import { entries } from 'lodash';
import { unwrapResult } from '@reduxjs/toolkit';
import './style.scss';

const colSpacing = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 24,
  xxl: 24,
};

const NationalId = ({ onSubmitNationalId }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const user = useSelector((store) => store.auth.currentUser);
  const { t } = useTranslation('screens');

  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // const [initialValue, setInitialValue] = useState(null);

  // useEffect(() => {
  //   const initialValueFromForm = form.getFieldValue('national_id');
  //   setInitialValue(initialValueFromForm);
  // }, [form, initialValue]);

  useEffect(() => {
    if (user.national_id) setIsButtonDisabled(false);
  }, [user]);

  const validateNationalID = (rule, value, callback) => {
    const nationalIDRegex = /^(2|3)[0-9][1-9][0-1][1-9][0-3][1-9](01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d$/;

    if (!value) {
      callback(t('checkout.national_id_validation.required'));
    } else if (!nationalIDRegex.test(value)) {
      callback(t('checkout.national_id_validation.valid'));
    } else {
      callback();
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setIsButtonDisabled(!allValues.national_id); // Disable button if national_id is empty
  };

  const onSubmit = (values) => {
    const currentValue = values.national_id;
    setLoading(true);
    dispatch(editProfile({ user: { national_id: currentValue } }))
      .then(unwrapResult)
      .then(() => {
        onSubmitNationalId();
      })
      .catch((errors) => {
        entries(errors)?.forEach(([name, errors]) => {
          form.setFields([
            {
              name,
              errors,
            },
          ]);
        });
      });
  };

  return (
    <Form
      form={form}
      className="national-id-form"
      initialValues={{ national_id: user.national_id }}
      onFinish={onSubmit}
      onValuesChange={onValuesChange}
    >
      <Row gutter={20}>
        <Col {...colSpacing}>
          <Form.Item
            colon={false}
            name="national_id"
            label={t('checkout.national_id')}
            extra={t('checkout.national_id_extra')}
            rules={[
              {
                validator: validateNationalID,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              className="submit-btn"
              htmlType="submit"
              type="primary"
              loading={loading}
              disabled={isButtonDisabled}
            >
              {t('checkout.submit')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default NationalId;
