import { useState, useEffect } from 'react';
import { Col, Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Logo from 'assets/images/Abdelaziz-stores-logo.png';
import Signup from '../Signup';
import Signin from '../Signin';
import './index.scss';

const TabGroup = () => {
  const { t } = useTranslation('authentication');
  const history = useHistory();
  const location = useLocation();
  const [isSignInTab, setIsSignInTab] = useState(true);
  const [activeKey, setActiveKey] = useState('signIn');

  const handleTabChange = (key) => {
    setIsSignInTab(key === 'signIn');
    setActiveKey(key);
    if (key === 'signIn') {
      history.push('/auth/signin');
    } else {
      history.push('/auth/register');
    }
  };

  useEffect(() => {
    if (location.pathname === '/auth/register') {
      setActiveKey('register');
      setIsSignInTab(false);
    } else {
      setActiveKey('signIn');
      setIsSignInTab(true);
    }
  }, [location.pathname]);

  return (
    <div className="text">
      <div className="auth-wrapper">
        <Row className="header-wrapper">
          <a href="/">
            <img className="logo" src={Logo} alt="Abdul Aziz Logo" />
          </a>

          <span className="header-title">
            {isSignInTab ? (
              <>
                {t('dont_have_an_account')}{' '}
                <button onClick={() => handleTabChange('register')}> {t('register')}</button>
              </>
            ) : (
              <>
                {t('already_have_an_account')} <button onClick={() => handleTabChange('signIn')}> {t('signin')}</button>
              </>
            )}
          </span>
        </Row>
        <Row className="tabs-wrapper" gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={14} xxl={14}>
            <div className="banner-wrapper">
              {isSignInTab ? (
                <>
                  <span>{t('signin_banner_1')}</span>
                  <span>{t('signin_banner_2')}</span>
                </>
              ) : (
                <>
                  <span>{t('signup_banner_1')}</span>
                  <span>{t('signup_banner_2')}</span>
                </>
              )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={10} xxl={10}>
            <Tabs className="tabs" activeKey={activeKey} onChange={handleTabChange}>
              <Tabs.TabPane className="signIn-tab" tab={t('signin')} key="signIn">
                <Signin history={history} />
              </Tabs.TabPane>
              <Tabs.TabPane className="register-tab" tab={t('register')} key="register">
                <Signup history={history} />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>

        <span className="copyright">{t('copyright')}</span>
      </div>
    </div>
  );
};

export default TabGroup;
