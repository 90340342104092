import { Steps, Row, Col, Modal, Typography, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import ChooseAddress from './components/ChooseAddress';
import OrderSummary from 'components/organsims/OrderSummary';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PaymentMethod from './components/PaymentMethod';
import { submitOrderAddress } from 'redux/orders/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchCart } from 'redux/cart/thunks';
import { cartErrors, cartLoading, totalCheckoutPrice } from 'redux/cart/selectors';
import NationalId from './components/nationalId';
import useMedia from 'react-use/lib/useMedia';
import { useHistory } from 'react-router-dom';
import CartScreen from 'screens/Guest/Cart';
import { ReactComponent as Step1 } from 'assets/icons/step1.svg';
import { ReactComponent as Step2 } from 'assets/icons/step2.svg';
import { ReactComponent as Step3 } from 'assets/icons/step3.svg';
import { ReactComponent as Step4 } from 'assets/icons/step4.svg';
import { ReactComponent as Step1Active } from 'assets/icons/step1active.svg';
import { ReactComponent as Step2Active } from 'assets/icons/step2active.svg';
import { ReactComponent as Step3Active } from 'assets/icons/step3active.svg';
import { ReactComponent as Step4Active } from 'assets/icons/step4active.svg';
import { ReactComponent as Success } from 'assets/icons/sucess.svg';
import './index.scss';

const Checkout = () => {
  const { t } = useTranslation('screens');
  const history = useHistory();
  const dispatch = useDispatch();
  const order_id = useSelector((state) => state.cart.order_id);
  const totalCheckout = useSelector(totalCheckoutPrice);
  const loading = useSelector(cartLoading);
  const errors = useSelector(cartErrors);
  const [opend, setOpend] = useState(false);
  const [checkoutStep, setCheckoutStep] = useState();
  const mobileView = useMedia('(max-width: 576px)');
  const stepPaths = useMemo(() => ['cart', 'shipping', totalCheckout >= 25000 ? 'national-id' : 'payment', 'payment'], [
    totalCheckout,
  ]);

  useEffect(() => {
    const currentPath = window.location.pathname.split('/').pop();
    const stepIndex = stepPaths.indexOf(currentPath);

    stepIndex === 0 ? setCheckoutStep(0) : setCheckoutStep(stepIndex);
  }, [stepPaths]);

  const updateStepPath = (step) => {
    const stepPath = stepPaths[step];
    if (stepPath) {
      history.replace(`/checkout/${stepPath}`);
    }
  };

  useEffect(() => {
    updateStepPath(checkoutStep);
  }, [checkoutStep]);

  const stepIcons = useMemo(
    () => ({
      0: checkoutStep === 0 ? <Step1Active /> : checkoutStep > 0 ? <Success /> : <Step1 />,
      1: checkoutStep === 1 ? <Step2Active /> : checkoutStep > 1 ? <Success /> : <Step2 />,
      2: checkoutStep === 2 ? <Step3Active /> : checkoutStep > 2 ? <Success /> : <Step3 />,
      3: checkoutStep === 3 ? <Step4Active /> : checkoutStep > 3 ? <Success /> : <Step4 />,
    }),
    [checkoutStep],
  );

  useEffect(() => {
    if (!loading && errors && errors.sap_sync) {
      setOpend(true);
      Modal.error({
        content: (
          <>
            {Object.keys(errors).map((errorKey) => (
              <Typography.Text>{errors[errorKey]}</Typography.Text>
            ))}
          </>
        ),
        okText: t('checkout.continue'),
        onOk: () => {
          setOpend(false);
          history.push('/');
        },
      });
    }
  }, [errors]);

  const onStepChange = (step) => {
    if (step < checkoutStep) {
      setCheckoutStep(step);
      updateStepPath(step);
    }
  };

  const onPickAddress = useCallback(
    (address_id) => {
      dispatch(submitOrderAddress({ address_id, order_id })).then(() =>
        dispatch(fetchCart())
          .then(unwrapResult)
          .then(() => {
            setCheckoutStep(2);
            updateStepPath(2);
          })
          .catch(() => {
            if (errors?.sap_sync) Modal.error({ title: t('signin.errors.title'), okText: t('signin.errors.title') });
          }),
      );
    },
    [dispatch, setCheckoutStep, order_id, t],
  );

  const onSubmitNationalId = () => {
    setCheckoutStep(3);
    updateStepPath(3);
  };

  const onReload = () => {
    setCheckoutStep(0);
    updateStepPath(0);
  };

  const nationalIdRequired = () => totalCheckout >= 25_000;

  return (
    <>
      <Steps className="steps-wrapper" current={checkoutStep} onChange={onStepChange}>
        <Steps.Step
          title={
            <>
              {!mobileView && <div className="step-subtitle">{t('checkout.steps.step1')}</div>}
              {t('checkout.steps.cart')}
            </>
          }
          icon={stepIcons[0]}
        />
        <Steps.Step
          title={
            <>
              {!mobileView && <div className="step-subtitle">{t('checkout.steps.step2')}</div>}
              {t('checkout.steps.shipping')}
            </>
          }
          icon={stepIcons[1]}
        />
        {nationalIdRequired() && (
          <Steps.Step
            title={
              <>
                {!mobileView && <div className="step-subtitle">{t('checkout.steps.step3')}</div>}
                {t('checkout.steps.add_national_id')}
              </>
            }
            icon={stepIcons[2]}
          />
        )}
        <Steps.Step
          title={
            <>
              {!mobileView && (
                <div className="step-subtitle">
                  {nationalIdRequired() ? t('checkout.steps.step4') : t('checkout.steps.step3')}
                </div>
              )}
              {t('checkout.steps.payment_method')}
            </>
          }
          icon={stepIcons[3]}
        />
      </Steps>

      <Row className="checkout-wrapper" gutter={[20, 20]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={14}>
          <>
            {checkoutStep === 0 ? (
              <CartScreen />
            ) : checkoutStep === 1 ? (
              <ChooseAddress onPickAddress={onPickAddress} />
            ) : checkoutStep === 2 && nationalIdRequired() ? (
              <NationalId onSubmitNationalId={onSubmitNationalId} />
            ) : (
              <PaymentMethod onReload={onReload} />
            )}
          </>
        </Col>
        {(checkoutStep !== 2 || !nationalIdRequired()) && (
          <Col className="cart-summary" xs={24} sm={24} md={24} lg={8} xl={10} xxl={10}>
            <Spin spinning={loading}>
              {!loading && (
                <OrderSummary
                  showDetails={nationalIdRequired() ? checkoutStep === 3 : checkoutStep === 2}
                  showButton={
                    nationalIdRequired()
                      ? checkoutStep !== 3 && checkoutStep !== 1
                      : checkoutStep !== 2 && checkoutStep !== 1
                  }
                  setCheckoutStep={setCheckoutStep}
                  showTitle={nationalIdRequired() ? checkoutStep === 3 : checkoutStep === 2}
                />
              )}
            </Spin>
          </Col>
        )}
      </Row>
    </>
  );
};
export default Checkout;
