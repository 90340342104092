import { useState, useCallback, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import CollapsibleSection from 'components/atoms/CollapsedSection/CollapsedSection';
import { List, Typography, Slider, Checkbox, Select, Space, InputNumber, Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';
import pickBy from 'lodash/pickBy';
import Brands from 'redux/brands';
import Categories from 'redux/categories';

import { useLoadingSelector } from 'redux/selectors';
import { fetchBrands } from 'redux/brands/thunks';
import { fetchCategories } from 'redux/categories/thunks';

import { unwrapResult } from '@reduxjs/toolkit';
import InfiniteScroll from 'react-infinite-scroller';
import store from 'redux/store';
import { fetchProducts } from 'redux/products/thunks';
import './index.scss';

const SideFilter = ({ onFilterSubmit, showCategory, hideBrand }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { promotionId } = useParams();
  const { t, i18n } = useTranslation('molecules');

  const [selectedBrands, setSelectedBrands] = useState({});
  const [selectedCategory, setSelectedCategory] = useState();
  const [priceRange, setPriceRange] = useState();
  const [priceFilter, setPriceFilter] = useState([]);
  const [brandsList, setBrandsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const brandsSelector = useSelector(Brands.selectors.byId);
  const childrenCategories = useSelector(Categories.selectors.childrenCategories);
  const isBrandsLoading = useLoadingSelector(fetchBrands);
  let searchParams = queryString.parse(search);

  const brandsMeta = store.getState()._pagination[
    `brands/fetchAll${searchParams?.category_id ? `?category_id=${searchParams.category_id}` : ''}`
  ];

  const onCategorySelect = useCallback(
    (item) => {
      setSelectedCategory(item);
      onFilterSubmit({
        priceRange: priceFilter,
        brands: Object.keys(selectedBrands),
        category_id: item,
      });
    },
    [onFilterSubmit, priceFilter, selectedBrands],
  );

  const onBrandsFetch = useCallback(() => {
    dispatch(fetchBrands({ params: { category_id: searchParams?.category_id } }))
      .then(unwrapResult)
      .then((response) => {
        setBrandsList([...brandsList, ...response.result]);
      });
  }, [brandsList, dispatch, searchParams?.category_id]);

  const onPressItem = useCallback(
    (item) => () => {
      const updatedSelectedBrands = selectedBrands[item]
        ? pickBy(selectedBrands, (_, key) => key !== item.toString())
        : { ...selectedBrands, [item]: true };
      setSelectedBrands(updatedSelectedBrands);
      onFilterSubmit({
        priceRange: priceFilter,
        brands: Object.keys(updatedSelectedBrands),
        category_id: selectedCategory,
      });
    },
    [selectedBrands, onFilterSubmit, priceFilter, selectedCategory],
  );
  const handleSliderChange = (value) => {
    setPriceFilter(value);
    onFilterSubmit({
      priceRange: value,
      brands: Object.keys(selectedBrands),
      category_id: selectedCategory,
    });
  };

  const onLowPriceChange = (value) => {
    const newPriceRange = [value, priceFilter[1]];
    setPriceFilter(newPriceRange);
    onFilterSubmit({
      priceRange: newPriceRange,
      brands: Object.keys(selectedBrands),
      category_id: selectedCategory,
    });
  };

  const onHighPriceChange = (value) => {
    const newPriceRange = [priceFilter[0], value];
    setPriceFilter(newPriceRange);
    onFilterSubmit({
      priceRange: newPriceRange,
      brands: Object.keys(selectedBrands),
      category_id: selectedCategory,
    });
  };

  const renderBrandsItem = useCallback(
    (item) => {
      const brand = brandsSelector(item)[0];
      return (
        <div>
          <Checkbox checked={selectedBrands[item]} onChange={onPressItem(item)}>
            <Typography.Text type={selectedBrands[item] ? 'primary' : 'secondary'}>
              {i18n.language === 'ar' && brand.name_ar ? brand.name_ar : brand.name}
            </Typography.Text>
          </Checkbox>
        </div>
      );
    },
    [brandsSelector, i18n.language, onPressItem, selectedBrands],
  );
  /**Fetch brands and products(to update price) when category changes */
  useEffect(() => {
    // Reset filters when navigating back to the brand page
    if (!searchParams?.category_id && !promotionId) {
      setSelectedBrands({});
      setSelectedCategory(undefined);
      setPriceFilter([]);
    }

    dispatch(
      fetchBrands({ params: { category_id: searchParams?.category_id, promotion_id: promotionId }, refresh: true }),
    )
      .then(unwrapResult)
      .then((response) => {
        setSelectedBrands({});
        setBrandsList(response.result);
      });
  }, [dispatch, promotionId, searchParams?.category_id]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchCategories({ params: { category_id: searchParams?.category_id }, refresh: true }))
      .then(unwrapResult)
      .then((response) => {
        setSelectedBrands({});
      });
    dispatch(fetchProducts({ params: { category_id: searchParams?.category_id }, refresh: true }))
      .then(unwrapResult)
      .then((response) => {
        setPriceRange([response.meta.min_price, response.meta.max_price]);
        setPriceFilter([response.meta.min_price, response.meta.max_price]);
        setIsLoading(false);
      });
  }, [dispatch, searchParams?.category_id]);
  // searchParams?.category_id

  return (
    <div className="filter-container">
      <div className="filter-info-wrapper">
        <CollapsibleSection title={t('side-filter.price')}>
          <div gutter={16} className="price-range-wrapper" justify="center">
            {isLoading ? (
              <Spin />
            ) : (
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Space size="small" className="price-wrapper">
                    <Typography.Text>{t('side-filter.low-price')}</Typography.Text>
                    <InputNumber
                      parser={(value) => value?.replace(/[^\d]/g, '')}
                      size="small"
                      value={Math.floor(priceFilter?.[0])}
                      onChange={onLowPriceChange}
                      min={0}
                      placeholder={Math.floor(priceRange?.[0])}
                    />
                  </Space>
                </Col>
                <Col className="high-price" xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Space size="small" className="price-wrapper">
                    <Typography.Text>{t('side-filter.high-price')}</Typography.Text>
                    <InputNumber
                      parser={(value) => value?.replace(/[^\d]/g, '')}
                      size="small"
                      value={Math.floor(priceFilter[1])}
                      onChange={onHighPriceChange}
                      min={0}
                      placeholder={Math.floor(priceRange?.[0])}
                    />
                  </Space>
                </Col>
                <Col className="slider-wrapper" xs={24} sm={24} md={24}>
                  <Slider
                    range={{ draggableTrack: true }}
                    value={priceFilter}
                    defaultValue={priceFilter}
                    min={priceRange?.[0]}
                    max={priceRange?.[1]}
                    onChange={handleSliderChange}
                  />
                </Col>
              </Row>
            )}
          </div>
        </CollapsibleSection>
        {!hideBrand && (
          <Fragment>
            <CollapsibleSection title={t('side-filter.brand')}>
              <div className="list-infinite-container">
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={onBrandsFetch}
                  hasMore={!isBrandsLoading && brandsMeta?.next_page}
                  useWindow={false}
                >
                  <List dataSource={brandsList} renderItem={renderBrandsItem} loading={isBrandsLoading} />
                </InfiniteScroll>
              </div>
            </CollapsibleSection>
          </Fragment>
        )}

        {showCategory ? (
          <div>
            <CollapsibleSection title={t('side-filter.category')}>
              <Select
                className="filter-category"
                placeholder={t('side-filter.category-Select')}
                onChange={onCategorySelect}
                value={selectedCategory}
                allowClear
              >
                {childrenCategories.map((category) => (
                  <Select.Option value={category.id}>{category.name}</Select.Option>
                ))}
              </Select>
            </CollapsibleSection>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

SideFilter.PropType = {
  initialPriceRange: PropTypes.array,
  priceBoundaries: PropTypes.array,
  onFilterSubmit: PropTypes.func,
  showCategory: PropTypes.bool,
};
export default SideFilter;
