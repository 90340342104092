import React, { useCallback } from 'react';
import { Card, Typography, Button, Col, Row, Space, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import useMedia from 'react-use/lib/useMedia';
import PropTypes from 'prop-types';
import ItemCounter from 'components/atoms/ItemCounter';
import Price from 'components/atoms/Price';
import localizeNumber from 'utils/localizeNumber';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { useDispatch, useSelector } from 'react-redux';
import Products from 'redux/products';
import { removeFromCart } from 'redux/cart/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { productCartCounter } from 'redux/cart/selectors';
import { Link } from 'react-router-dom';
import './index.scss';

const ProductPreview = ({ productId, isDetailed, onCountChange, productOrder }) => {
  const dispatch = useDispatch();
  const currentProduct = useSelector((state) => Products.selectors.selectById(state, productId));

  let product = currentProduct;
  if (productOrder) product = productOrder;

  const productCount = useSelector(productCartCounter)(productId);
  const { t } = useTranslation('product-preview');
  const mobileView = useMedia('(max-width: 768px)');

  const onRemovePress = () => {
    dispatch(removeFromCart({ order_product: { product_id: productId, _destroy: 'true' } }))
      .then(unwrapResult)
      .catch((error) => {
        Modal.error({
          content: (
            <>
              {error?.errors?.map((errorItem) => (
                <Typography.Text>{errorItem}</Typography.Text>
              ))}
            </>
          ),
        });
      });
  };

  const _onCountChange = useCallback(
    (count) => {
      onCountChange(productId, count);
    },
    [onCountChange, productId],
  );

  const {
    id,
    title,
    price,
    price_after_promotion,
    checkout_price,
    images,
    stock,
    quantity,
    promotion,
    has_active_promotion,
    percentage,
  } = product;

  return (
    <div className={`product-preview-wrapper ${!isDetailed ? 'editable-bordered-section' : ''}`}>
      <Card>
        {mobileView && (
          <Row className="remove-btn-wrapper">
            {isDetailed && (
              <Button className="remove-btn" type="text" onClick={onRemovePress}>
                <RemoveIcon />
              </Button>
            )}
          </Row>
        )}
        <Row gutter={[40, 20]}>
          <Col className="details-name" xs={24} sm={14} md={isDetailed ? 12 : 16} lg={isDetailed ? 12 : 16}>
            <Link to={`/products/${productId || id}`} className="product-link">
              <Col xxs={10} xs={5} sm={5} md={5} lg={5} xl={5} xxl={5}>
                <img src={images?.[0]?.versions.medium} alt="product" />
              </Col>
              <Col className="product-name-wrapper" xxs={14} xs={19} sm={19} md={19} lg={19} xl={19} xxl={19}>
                <Typography.Title
                  className={`product-name ${!isDetailed && 'custom-product-name'}`}
                  type="secondary"
                  level={2}
                >
                  {title}
                </Typography.Title>
                {mobileView && (
                  <div className="price-container">
                    {!checkout_price && promotion && has_active_promotion === true && percentage !== 0 ? (
                      <Space>
                        <Price text={` ${localizeNumber(price_after_promotion)} ${t('currency')} `} />
                        <Price text={` ${localizeNumber(price)} ${t('currency')}`} crossed />
                      </Space>
                    ) : (
                      <Price text={` ${localizeNumber(checkout_price || price)} ${t('currency')}`} />
                    )}
                    {!mobileView && isDetailed && (
                      <Button className="remove-btn" type="text" onClick={onRemovePress}>
                        <RemoveIcon />
                      </Button>
                    )}
                  </div>
                )}
              </Col>
            </Link>
          </Col>
          <Col className="details-counter" xs={24} sm={10} md={isDetailed ? 12 : 8} lg={isDetailed ? 12 : 8}>
            {isDetailed && (
              <Col sm={12} md={12} lg={12}>
                <Space direction="vertical" align="center">
                  <Row className="product-item-counter">
                    <ItemCounter
                      stockMaxValue={stock}
                      orderMaxValue={currentProduct?.maximum_number_per_order}
                      defaultValue={productCount}
                      onValueChange={_onCountChange}
                      onRemoveProduct={onRemovePress}
                    />
                  </Row>
                  {stock <= 3 && (
                    <Row>
                      <Typography.Text type="danger">{t('stock', { stock: stock })}</Typography.Text>
                    </Row>
                  )}
                </Space>
              </Col>
            )}
            {!mobileView && (
              <Col sm={isDetailed ? 12 : 24} md={isDetailed ? 12 : 24} lg={isDetailed ? 12 : 24}>
                <div className="price-container">
                  {!checkout_price && promotion && has_active_promotion === true && percentage !== 0 ? (
                    <Space>
                      <Price text={`${localizeNumber(price_after_promotion)} ${t('currency')} `} />
                      <Price text={`${localizeNumber(price)} ${t('currency')}`} crossed />
                    </Space>
                  ) : (
                    <Price text={`${localizeNumber(checkout_price || price)} ${t('currency')}`} />
                  )}
                </div>
                {!mobileView && isDetailed && (
                  <Button className="remove-btn" type="text" onClick={onRemovePress}>
                    <RemoveIcon />
                  </Button>
                )}
              </Col>
            )}
          </Col>

          {isDetailed && (
            <Space size="large" direction="vertical">
              <Space direction="vertical">
                {quantity && <Typography.Text type="secondary">{t('quantity', { quantity })}</Typography.Text>}
                {['refunded', 'partially_refunded'].includes(product?.order_status) && (
                  <Typography.Text type="secondary">
                    {t('refunded_quantity', { refunded_quantity: product?.refunded_quantity })}
                  </Typography.Text>
                )}
              </Space>
            </Space>
          )}
        </Row>
      </Card>
    </div>
  );
};

ProductPreview.propTypes = {
  isDetailed: PropTypes.bool,
  isFavorite: PropTypes.bool,
  product: PropTypes.object,
  count: PropTypes.number,
  onCountChange: PropTypes.func,
  onFavoritePress: PropTypes.func,
  onRemovePress: PropTypes.func,
};
export default ProductPreview;
