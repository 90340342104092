import React from 'react';
import PropTypes from 'prop-types';
import CardLabels from 'components/atoms/CardLabels';
import Price from 'components/atoms/Price';
import AddToWishlist from 'components/atoms/AddToWishlist/AddToWishlist';
import AddToCart from 'components/atoms/AddToCart/AddToCart';
import { Card, Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import localizeNumber from 'utils/localizeNumber';
import Products from 'redux/products';
import './index.scss';

const ProductCard = ({ productId, removeFromFavorites }) => {
  const { t, i18n } = useTranslation('product-card');
  const product = useSelector((state) => Products.selectors.selectById(state, productId));
  const { title, title_ar, images } = product || {};

  if (!product) {
    return null;
  }

  return (
    <div className="product-card-wrapper">
      <Card>
        <div className="top-card">
          {product?.featured && <CardLabels labels={[t('featured')]} />}
          <div className="favorites">
            <AddToWishlist product={product} showText={false} />
          </div>
        </div>
        <Link to={`/products/${productId}`}>
          <div className="card-cover">
            <img alt={title} src={images?.[0]?.versions?.thumbnail} />
          </div>
          <Typography.Title className="card-title" level={2}>
            {i18n.language === 'ar' ? title_ar : title}
          </Typography.Title>
        </Link>
        {product?.promotion && product.has_active_promotion === true && product.percentage !== 0 ? (
          <React.Fragment>
            <Space>
              <Price
                discounted
                text={t('price', {
                  price: localizeNumber(product?.price_after_promotion),
                })}
              />
              <Price
                text={t('price', {
                  price: localizeNumber(product?.price),
                })}
                crossed
              />
              {/* <Price
              discounted
              text={t('discount', {
                percentage: localizeNumber(
                  Number.parseFloat(100 - (product?.price_after_promotion * 100) / product?.price),
                  true,
                ),
              })}
            /> */}
            </Space>
          </React.Fragment>
        ) : (
          <Price
            text={t('price', {
              price: localizeNumber(product?.price),
            })}
          />
        )}
        {/* {product?.stock === 0 && <Typography.Text type="danger">{t('out_stock')}</Typography.Text>} */}
        <div className="add-to-cart-wrapper">
          <AddToCart id={productId} isAvailable={product?.stock !== 0} />
        </div>
      </Card>
    </div>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object,
  removeFromFavorites: PropTypes.func,
};

export default ProductCard;
