import { Card, Typography, Col, Row, Space, Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import useMedia from 'react-use/lib/useMedia';
import PropTypes from 'prop-types';
import Price from 'components/atoms/Price';
import localizeNumber from 'utils/localizeNumber';
import { useSelector } from 'react-redux';
import Products from 'redux/products';
import { Link } from 'react-router-dom';
import AddToCart from 'components/atoms/AddToCart/AddToCart';
import { ReactComponent as RemoveIcon } from 'assets/icons/trash.svg';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import './index.scss';

const WishlistProductPreview = ({ productId }) => {
  const currentProduct = useSelector((state) => Products.selectors.selectById(state, productId));
  const dispatch = useDispatch();
  const { t: screenTranslation } = useTranslation('screens');

  let product = currentProduct;

  const { t } = useTranslation('product-preview');
  const tabletView = useMedia('(min-width:576px) and (max-width: 991px)');

  const {
    id,
    title,
    price,
    price_after_promotion,
    checkout_price,
    images,
    promotion,
    has_active_promotion,
    percentage,
  } = product;

  const onRemovePress = () => {
    Modal.confirm({
      content: screenTranslation('my_account.wishlist.remove.content'),
      okText: screenTranslation('my_account.wishlist.remove.ok'),
      onOk: () => {
        dispatch(Products.thunks.unfavoriteProduct({ id: product.id }))
          .then(unwrapResult)
          .catch((error) => Modal.error());
      },
    });
  };
  return (
    <div className="wishlist-product-preview-wrapper">
      <Card>
        <Row gutter={[40, 20]}>
          <Col className="details-name" xs={24} sm={24} md={24} lg={12} xl={13}>
            <Button className="remove-btn" type="text" onClick={onRemovePress}>
              <RemoveIcon />
            </Button>
            <Link to={`/products/${productId || id}`} className="product-link">
              <Col xxs={10} xs={10} sm={5} md={5} lg={5} xl={5} xxl={4}>
                <img src={images?.[0]?.versions.medium} alt="product" />
              </Col>
              <Col className="product-name-wrapper" xxs={14} xs={14} sm={10} md={10} lg={15} xl={15} xxl={16}>
                <Typography.Title className="product-name" type="secondary" level={2}>
                  {title}
                </Typography.Title>
              </Col>
              {tabletView && (
                <Col xxs={0} xs={0} sm={9} md={9} lg={10} className="tablet-price-container">
                  <div className="price-container">
                    {!checkout_price && promotion && has_active_promotion === true && percentage !== 0 ? (
                      <Space>
                        <Price text={`${localizeNumber(price_after_promotion)} ${t('currency')} `} />
                        <Price text={`${localizeNumber(price)} ${t('currency')}`} crossed />
                      </Space>
                    ) : (
                      <Price text={`${localizeNumber(checkout_price || price)} ${t('currency')}`} />
                    )}
                  </div>
                </Col>
              )}
            </Link>
          </Col>
          <Col className="details-addToCart" xs={24} sm={24} md={24} lg={11} xl={10}>
            {!tabletView && (
              <Col xs={12} sm={8} md={8} lg={10} xl={10}>
                <div className="price-container">
                  {!checkout_price && promotion && has_active_promotion === true && percentage !== 0 ? (
                    <Space>
                      <Price text={`${localizeNumber(price_after_promotion)} ${t('currency')} `} />
                      <Price text={`${localizeNumber(price)} ${t('currency')}`} crossed />
                    </Space>
                  ) : (
                    <Price text={`${localizeNumber(checkout_price || price)} ${t('currency')}`} />
                  )}
                </div>
              </Col>
            )}

            <Col xs={12} sm={14} md={14} lg={14} xl={14}>
              <AddToCart id={productId} isAvailable={product?.stock !== 0} />
            </Col>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

WishlistProductPreview.propTypes = {
  product: PropTypes.object,
};
export default WishlistProductPreview;
