import { Card, Typography, Space, List, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import Branches from 'redux/branches';
import './index.scss';

const renderPhoneNumber = (item) => (
  <List.Item>
    <Typography.Text type="secondary">{item.number}</Typography.Text>
  </List.Item>
);

const BranchItem = ({ id }) => {
  const branch = useSelector((state) => Branches.selectors.selectById(state, id));
  const { name, address, phone_numbers, lat = 0, lng = 0 } = branch || {};
  return (
    <Card className="branch-card-wrapper">
      <Space direction="vertical" className="branch-description-wrapper">
        <Typography.Text className="card-title" strong type="secondary">
          {name}
        </Typography.Text>
        <a className="address-title" href={`https://maps.google.com?q=${lat},${lng}`} target="_blank" rel="noreferrer">
          <Typography.Text type="secondary">{address}</Typography.Text>
        </a>
        <Row className="phone-number-wrapper">
          <Col span={20}>
            <List
              className="phone-number"
              grid={{ gutter: 12, column: 2 }}
              dataSource={phone_numbers}
              renderItem={renderPhoneNumber}
            />
          </Col>
        </Row>
      </Space>
    </Card>
  );
};
export default BranchItem;
