import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row, Space, Typography, Skeleton } from 'antd';
import AddToWishlist from 'components/atoms/AddToWishlist/AddToWishlist';
import { useTranslation } from 'react-i18next';
import AddToCart from 'components/atoms/AddToCart/AddToCart';
import { FullscreenOutlined, FullscreenExitOutlined } from '@ant-design/icons';
import useMedia from 'react-use/lib/useMedia';
import { fetchProduct } from 'redux/products/thunks';
import Products from 'redux/products';
import Lightbox from 'react-awesome-lightbox';
import 'react-awesome-lightbox/build/style.css';
import './index.scss';
import Price from 'components/atoms/Price';
import localizeNumber from 'utils/localizeNumber';
import isEmpty from 'lodash/isEmpty';
import inStock from 'assets/icons/inStock.svg';
import stockDanger from 'assets/icons/stockDanger.svg';
import stockLimit from 'assets/icons/stockLimit.svg';

const ProductDetail = ({ title, value }) => (
  <Row className="product-detail-wrapper">
    <Col xxs={11} xs={11} sm={11} md={11} lg={11} xl={11}>
      <Typography.Text className="product-detail">{title}</Typography.Text>
    </Col>
    <Col xxs={11} xs={11} sm={12} md={12} lg={12} xl={12}>
      <Typography.Text className="product-detail">{value}</Typography.Text>
    </Col>
  </Row>
);

const SingleProduct = ({ productId }) => {
  const product = useSelector(Products.selectors.selectSingleProduct)(productId);

  const history = useHistory();
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation('molecules');
  const { t: globalTranslation } = useTranslation('global');
  const isSmall = useMedia('(max-width: 576px)');

  const [fullScreenMode, toggleFullScreenMode] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const { id, specifications = [], images = [], stock, price = 0, price_after_promotion = 0 } = product;

  const handleFullScreenMode = () => {
    toggleFullScreenMode(true);
  };

  const closeFullScreenMode = () => {
    toggleFullScreenMode(false);
  };
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    handleFullScreenMode();
  };
  useEffect(() => {
    dispatch(fetchProduct({ id: productId }))
      .then(unwrapResult)
      .catch((error) => {
        if (error.status === 404) history.replace('/404');
      });
  }, [dispatch, history, productId]);

  const imageUrlMappings = images?.map((image) => image.original);

  return (
    <div className="single-product">
      {isEmpty(product) ? (
        <Skeleton active paragraph={{ rows: 4 }} avatar={{ shape: 'square', size: 60 }} />
      ) : (
        <Row className="single-product-wrapper" gutter={[40, 40]}>
          {imageUrlMappings.length > 0 && (
            <Col xs={24} sm={24} md={24} lg={14} xl={9} xxl={7} className="image-gallery-wrapper">
              {fullScreenMode && (
                <Lightbox
                  images={imageUrlMappings || []}
                  onClose={closeFullScreenMode}
                  startIndex={selectedImageIndex}
                />
              )}
              <ImageGallery
                showNav={false}
                items={images}
                thumbnailPosition={isSmall ? 'bottom' : 'left'}
                showPlayButton={false}
                onClick={(e) => handleImageClick(selectedImageIndex)}
                onImageClick={(e) => handleImageClick(selectedImageIndex)}
                onSlide={(currentIndex) => setSelectedImageIndex(currentIndex)}
                renderFullscreenButton={(onClick, isFullScreen) => (
                  <Button
                    onClick={onClick}
                    type="link"
                    icon={!isFullScreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
                  ></Button>
                )}
              />
            </Col>
          )}
          <Col xs={24} sm={24} md={24} lg={10} xl={15} xxl={17} className="product-details-wrapper">
            <Space size="large" direction="vertical">
              <Typography.Text className="product-title">{product[`title_${i18n.language}`]}</Typography.Text>
              <div className="product-price-wrapper">
                {product?.promotion && product.has_active_promotion === true && product.percentage !== 0 ? (
                  <Space>
                    <Price text={`${localizeNumber(price_after_promotion)} ${globalTranslation('currency')}`} />
                    <Price text={`${localizeNumber(price)} ${globalTranslation('currency')}`} crossed />
                  </Space>
                ) : (
                  <Price text={`${localizeNumber(price)} ${globalTranslation('currency')}`} />
                )}
              </div>
              <Typography.Text className="description">{product[`description_${i18n.language}`]}</Typography.Text>
              <Row className="add-to-favorite-wrapper">
                <Col>
                  <AddToWishlist className="add-to-favorite" product={product} />
                </Col>
                <Col className="stock-wrapper">
                  {stock > 3 && (
                    <Row>
                      <img className="stock-ico" src={inStock} alt="Stock Icon" />
                      <Typography.Text type="success">{t('single_product.in_stock')}</Typography.Text>
                    </Row>
                  )}
                  {stock <= 3 && stock > 0 && (
                    <Row>
                      <img className="stock-ico" src={stockLimit} alt="Stock Icon" />
                      <Typography.Text type="warning">{t('single_product.stock', { stock })}</Typography.Text>
                    </Row>
                  )}
                  {stock === 0 && (
                    <Row>
                      <img className="stock-ico" src={stockDanger} alt="Stock Icon" />
                      <Typography.Text type="danger">{t('single_product.out_stock')}</Typography.Text>
                    </Row>
                  )}
                </Col>
              </Row>
              <AddToCart id={id} isAvailable={stock !== 0} showCounter={true} />
            </Space>
          </Col>
        </Row>
      )}
      <div>
        {!isEmpty(specifications) && (
          <div className="specs-wrapper">
            <Typography.Title className="specs-title">{t('single_product.specs')}</Typography.Title>
            {specifications.map((spec) => (
              <ProductDetail
                className="product-detail"
                key={spec.id}
                title={spec[`key_${i18n.language}`]}
                value={spec[`value_${i18n.language}`]}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleProduct;
