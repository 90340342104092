import { useState, useCallback, useEffect } from 'react';
import { AutoComplete, Input, Typography, Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import CartIcon from 'components/molecules/CartIcon';
import { SearchOutlined } from '@ant-design/icons';
import Logo from 'assets/images/Abdelaziz-stores-logo.png';
import { ReactComponent as Hotline } from 'assets/icons/hotline.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProducts } from 'redux/products/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import BurgerMenu from 'components/molecules/BurgerMenu';
import SearchResults from './components/SearchResults';
import CategoriesHeader from '../Header/components/CategoriesHeader';
import { isSignedInSelector } from 'redux/auth/selectors';
import './index.scss';

const SearchBar = ({ onSearchPress, onCategoryPress }) => {
  const { t } = useTranslation('search-bar');
  const [searchKey, setSearchKey] = useState('');
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const mobileView = useMedia('(max-width: 769px)');
  const [searchOptions, setSearchOptions] = useState([]);

  const isSignedIn = useSelector(isSignedInSelector);

  const history = useHistory();
  const dispatch = useDispatch();

  const searchResults = useCallback(
    (query) => {
      dispatch(fetchProducts({ params: { query }, refresh: true }))
        .then(unwrapResult)
        .then((response) => {
          setSearchOptions(
            response?.result
              ? response?.result.map((id) => {
                  return {
                    value: id,
                    label: <SearchResults id={id} />,
                  };
                })
              : [],
          );
          setIsSearchVisible(true);
        });
    },
    [dispatch],
  );

  const onSearchIconClick = useCallback(() => {
    setIsSearchVisible(!isSearchVisible);
  }, [isSearchVisible]);

  const onSearchSubmit = useCallback(() => {
    if (isSearchVisible) setIsSearchVisible(false);
    if (searchKey.length !== 0) onSearchPress(searchKey);
  }, [isSearchVisible, onSearchPress, searchKey]);

  const onOptionSelect = useCallback(
    (value, _) => {
      history.push(`/products/${value}`);
      setIsSearchVisible(false);
      setSearchKey('');
    },
    [history],
  );

  const delayedSearch = debounce(
    useCallback((value) => searchResults(value), [searchResults]),
    500,
  );

  const onChange = useCallback((event) => {
    const value = event?.target?.value;
    setSearchKey(value);
    if (value) {
      setIsSearchVisible(true);
    } else {
      setIsSearchVisible(false);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsSearchVisible(false);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="search-bar-wrapper">
        <div>
          <BurgerMenu onCategoryPress={onCategoryPress} />
          {!mobileView && (
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          )}
        </div>
        {mobileView && (
          <div>
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
        )}
        <AutoComplete
          options={searchOptions}
          onSelect={onOptionSelect}
          onSearch={delayedSearch}
          value={searchKey}
          className="search-bar"
          open={isSearchVisible}
        >
          <Input.Search
            placeholder={t('placeholder')}
            onSearch={onSearchSubmit}
            enterButton
            size="large"
            onChange={onChange}
          />
        </AutoComplete>
        <Modal
          visible={isSearchVisible && mobileView}
          closable={false}
          footer={null}
          onCancel={() => setIsSearchVisible(false)}
          className="search-modal"
        >
          <AutoComplete value={searchKey} options={searchOptions} onSelect={onOptionSelect} onSearch={delayedSearch}>
            <Input.Search
              placeholder={t('placeholder')}
              onSearch={onSearchSubmit}
              enterButton
              size="small"
              onChange={onChange}
            />
          </AutoComplete>
        </Modal>
        {mobileView && (
          <div>
            <SearchOutlined className="search-icon" onClick={onSearchIconClick} />
            <CartIcon link="/checkout/cart" />
          </div>
        )}
        {!mobileView && (
          <div className="btns-wrapper">
            {!isSignedIn && (
              <div className="authentication-wrapper">
                <Link to="/auth/register">
                  <Button className="authentication-btn" type="text">
                    {t('register')}
                  </Button>
                </Link>
                <Link to="/auth/signin">
                  <Button className="authentication-btn login-btn" type="text">
                    {t('login')}
                  </Button>
                </Link>
              </div>
            )}
            <div className="hotline-wrapper">
              <Hotline className="hotline-icon" />
              <Typography.Text className="hotline">16581</Typography.Text>
            </div>
          </div>
        )}
      </div>
      <CategoriesHeader onCategoryPress={onCategoryPress} />
    </>
  );
};
SearchBar.PropType = {
  onSearchPress: PropTypes.func,
  numberOfItems: PropTypes.number,
};
export default SearchBar;
