import { useCallback, useState } from 'react';
import { Col, Modal, Row, Space, Typography, Radio } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useHistory } from 'react-router-dom';
import { submitOrderAddress } from 'redux/orders/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { cartErrors } from 'redux/cart/selectors';
import { fetchCart } from 'redux/cart/thunks';
import Addresses from 'redux/addresses';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { deleteAddress } from 'redux/addresses/thunks';
import { useMedia } from 'react-use';
import './index.scss';

const AddressItem = ({ addressId, isDetailed, onPickAddress, isSelected }) => {
  const { t } = useTranslation('screens');
  const history = useHistory();
  const dispatch = useDispatch();
  const address = useSelector((state) => Addresses.selectors.selectById(state, addressId));
  const user = useSelector((store) => store.auth.currentUser);
  const [loading, setLoading] = useState(false);
  const order_id = useSelector((state) => state.cart.order_id);
  const errors = useSelector(cartErrors);
  const location = useLocation();
  const mobileView = useMedia('(max-width: 575px)');

  const onEditAddress = useCallback(() => {
    history.push({
      pathname: `/address/${addressId}`,
      state: { from: location.pathname },
    });
  }, [addressId, history]);

  const onChooseAddress = (address_id) => {
    // setSelectedAddressId(address_id);

    setLoading(true);
    dispatch(submitOrderAddress({ address_id, order_id })).then(() =>
      dispatch(fetchCart())
        .then(unwrapResult)
        .catch(() => {
          if (errors?.sap_sync) Modal.error({ title: t('signin.errors.title'), okText: t('signin.errors.title') });
        }),
    );
  };

  const onDeleteAddress = useCallback(() => {
    Modal.confirm({
      title: t('my_account.addresses.delete_confirm'),
      onOk() {
        dispatch(deleteAddress({ id: addressId }));
      },
    });
  }, [addressId, dispatch, t]);

  return (
    <>
      {!isDetailed ? (
        <Row className={`address-item-wrapper checkout-item-wrapper ${isSelected ? 'active-address' : ''}`}>
          <Col xxs={24} xs={24} sm={16} md={16} lg={16} xl={16} onClick={() => onChooseAddress(addressId)}>
            <Row className="mobile-editable">
              <Col>
                <Radio checked={isSelected} />
                {address.label === null ? (
                  <Typography.Text className="user-name">{user.full_name}</Typography.Text>
                ) : (
                  <Typography.Text className="user-name">{address.label}</Typography.Text>
                )}
              </Col>
              {mobileView && (
                <Col xs={4} sm={4}>
                  <Space align="center" className="address-action-btn">
                    <Col className="edit-button" xs={12} sm={12} m={12} lg={12} xl={12}>
                      <EditIcon onClick={onEditAddress} />
                    </Col>
                    <Col className="delete-button" xs={12} sm={12} m={12} lg={12} xl={12}>
                      <RemoveIcon onClick={onDeleteAddress} />
                    </Col>
                  </Space>
                </Col>
              )}
            </Row>
            <Row>
              <Typography.Text className="">
                {`${address.building} ${address.street_name} ${address.district} ${address.governorate} ${address.phone_number}`}
              </Typography.Text>
            </Row>
          </Col>
          {!mobileView && (
            <Col xxs={24} xs={24} sm={8} md={8} lg={8} xl={8}>
              <Space align="center" className="address-action-btn">
                <Col className="edit-button" xs={12} sm={12} m={12} lg={12} xl={12}>
                  <EditIcon onClick={onEditAddress} />
                </Col>
                <Col className="delete-button" xs={12} sm={12} m={12} lg={12} xl={12}>
                  <RemoveIcon onClick={onDeleteAddress} />
                </Col>
              </Space>
            </Col>
          )}
        </Row>
      ) : (
        <Row className="address-item-wrapper ">
          <Col xs={24} sm={24} m={24} lg={24} xl={24} className="address-info-wrapper">
            <Row>
              <Col xs={16} sm={16} m={16} lg={16} xl={16}>
                <Typography.Text strong className="address-label">
                  {address.label}{' '}
                </Typography.Text>
              </Col>
              <Col xs={8} sm={8} m={8} lg={8} xl={8}>
                <Space align="center" className="address-action-btn">
                  <Col className="edit-button" xs={12} sm={12} m={12} lg={12} xl={12}>
                    <EditIcon onClick={onEditAddress} />
                  </Col>
                  <Col className="delete-button" xs={12} sm={12} m={12} lg={12} xl={12}>
                    <RemoveIcon onClick={onDeleteAddress} />
                  </Col>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} m={24} lg={24} xl={24}>
                <Typography.Text className="address-info">{user.full_name} </Typography.Text>
              </Col>
              <Col xs={24} sm={24} m={24} lg={24} xl={24}>
                <Typography.Text className="address-info">{address.phone_number} </Typography.Text>
              </Col>
              <Col xs={24} sm={24} m={24} lg={24} xl={24}>
                <Typography.Text className="address-info detailed-address">
                  {address.building} {address.street_name}, {address.district} {address.governorate}
                </Typography.Text>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};
export default AddressItem;
