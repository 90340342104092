import { Collapse, Radio, Checkbox, Button, Row, Col, Typography, Modal, Divider, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { submitCheckout } from 'redux/payments/thunks';
import { unwrapResult } from '@reduxjs/toolkit';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useMountedState } from 'react-use';
import { createPayment } from 'redux/payments/thunks';
import { isArray } from 'lodash';
import { defaultIframeId } from './constants.js';
import { fetchInstallments } from 'redux/installments/thunks.js';
import Installments from 'redux/installments/index.js';
import i18n from 'translation/i18n.js';
import { fetchBanks } from 'redux/banks/thunks.js';
import Banks from 'redux/banks/index.js';
import './index.scss';
import BorderedSection from 'components/organsims/BorderedSection/index.jsx';

let iframeTimer;

const PaymentMethod = ({ onReload }) => {
  const [chosenBank, setChosenBank] = useState();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [installmentId, setInstallmentId] = useState();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const isMounted = useMountedState();
  const { t } = useTranslation('screens');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const paymentKeyExpirationTimestamp = useSelector((state) => state.payments.paymentKeyExpirationTimestamp);
  const banks = useSelector(Banks.selectors.selectAll);
  const installments = useSelector(Installments.selectors.selectAll);
  const [key, setKey] = useState('');

  const allowSubmitButton = () => {
    if (paymentMethod) {
      if (paymentMethod === 'online' || (paymentMethod === 'visa_on_delivery_installments' && !installmentId))
        return false;
      return true;
    }
    return false;
  };

  const initiatePayment = useCallback(() => {
    dispatch(createPayment()).then((response) => {
      setKey(response?.payload.payment_key);
    });
  }, [dispatch]);

  const installmentsOptions = useMemo(
    () =>
      installments.map((installment) => ({
        label: i18n.language === 'ar' ? installment.title_ar : installment.title_en,
        value: installment.id,
      })),
    [installments],
  );
  const banksOptions = useMemo(
    () =>
      banks.map((bank) => ({
        label: bank.name,
        value: bank.id,
      })),
    [banks],
  );

  const paymentTimeoutModalContent = (
    <>
      <Typography.Title level={3}>{t('checkout.payment_expired_error.title')}</Typography.Title>
      <Divider />
      <Divider />
      <Typography.Text>{t('checkout.payment_expired_error.content')}</Typography.Text>
    </>
  );
  const onPaymentChange = (e) => {
    const paymentMethod = e.target.value;
    setPaymentMethod(paymentMethod);
    if (key !== '' && paymentMethod === 'online') {
      if (!paymentKeyExpirationTimestamp || paymentKeyExpirationTimestamp < Date.now()) {
        dispatch(createPayment())
          .then(unwrapResult)
          .then(() => {
            clearTimeout(iframeTimer);
            iframeTimer = setTimeout(
              () =>
                Modal.error({
                  content: paymentTimeoutModalContent,
                  onOk: onReload,
                }),
              +process.env.REACT_APP_PAYMENT_TIMEOUT * 60 * 1000,
            );
          })
          .catch((errors) =>
            Modal.error({
              content: (
                <>
                  {isArray(errors) ? (
                    errors?.map((error) => <Typography.Text>{error}</Typography.Text>)
                  ) : (
                    <Typography.Text>{t('checkout.error.content')}</Typography.Text>
                  )}
                </>
              ),
            }),
          );
      } else if (paymentKeyExpirationTimestamp > Date.now()) {
        clearTimeout(iframeTimer);
        iframeTimer = setTimeout(
          () =>
            Modal.error({
              content: paymentTimeoutModalContent,
              onOk: onReload,
            }),
          paymentKeyExpirationTimestamp - Date.now(),
        );
      }
    }
  };

  const onContinue = () => {
    setLoading(true);
    dispatch(
      submitCheckout({
        payment_method: paymentMethod,
        ...(paymentMethod === 'visa_on_delivery_installments' && { bank_installment_id: installmentId }),
      }),
    )
      .then(unwrapResult)
      .then(() => {
        const success = queryString.stringify({ success: true });
        location.search = success;
        history.push('/complete-payment?' + location.search);
      })
      .catch((error) => {
        if (error?.status === 422) {
          Modal.error({
            // title: t('checkout.payment_error.title'),
            content: <Typography.Text>{error?.data.sap_sync} </Typography.Text>,
            content: (
              <>
                {error?.data.map((errorKey) => (
                  <Typography.Text>{errorKey}</Typography.Text>
                ))}
              </>
            ),
            okText: t('checkout.continue'),
            onOk: () => {
              history.push('/');
            },
          });
        }
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });
  };

  const onAgreementToggle = (e) => {
    setChecked(e.target.checked);
  };
  const onChooseBank = (bank_id) => {
    setChosenBank(bank_id);
    dispatch(fetchInstallments({ params: { bank_id, show_all: true } }));
  };

  useEffect(() => {
    return () => clearTimeout(iframeTimer);
  }, []);

  useEffect(() => {
    dispatch(fetchBanks({ params: { show_all: true } }));
  }, [dispatch]);

  const renderBankInstallments = () => {
    if (paymentMethod !== 'visa_on_delivery_installments') return null;
    return (
      <Space direction="vertical" size="small" className="bank-select-wrapper">
        <Select
          value={chosenBank}
          placeholder={t('checkout.banks')}
          options={banksOptions}
          optionLabelProp="label"
          onChange={onChooseBank}
        />
        {chosenBank && (
          <Select
            value={installmentId}
            placeholder={t('checkout.bank-installments')}
            options={installmentsOptions}
            optionLabelProp="label"
            onChange={setInstallmentId}
          />
        )}
      </Space>
    );
  };

  return (
    <div className="payment-method-form">
      <Typography.Text strong className="payment-methods">
        {t('checkout.payment_methods')}
      </Typography.Text>
      <BorderedSection>
        <Radio.Group value={paymentMethod} onChange={onPaymentChange}>
          <Radio value="online" onClick={initiatePayment}>
            {t('checkout.radio_buttons.visa')}
          </Radio>
          <Collapse accordion ghost activeKey={paymentMethod}>
            <Collapse.Panel key="online" showArrow={false}>
              {!key ? (
                <div className="loader">
                  <LoadingOutlined />
                </div>
              ) : (
                <iframe
                  title={t('checkout.title')}
                  width="100%"
                  height="900px"
                  src={`https://accept.paymobsolutions.com/api/acceptance/iframes/${
                    process.env.REACT_APP_IFRAME_ID || defaultIframeId
                  }?payment_token=${key}`}
                />
              )}
            </Collapse.Panel>
          </Collapse>
          {/* <Radio value="cash">{t('checkout.radio_buttons.delivery')}</Radio> */}
          <Radio value="visa_on_delivery">{t('checkout.radio_buttons.visa_on_delivery')}</Radio>
          <Radio value="visa_on_delivery_installments">
            {t('checkout.radio_buttons.visa_on_delivery_installments')}
          </Radio>
        </Radio.Group>
        {renderBankInstallments()}
        {allowSubmitButton() && (
          <Row>
            <Col span={24} className="checkbox-container">
              <Checkbox onChange={onAgreementToggle}>
                {t('checkout.agree')}
                <Link target="_blank" to="/terms-conditions">
                  {t('checkout.terms')}
                </Link>
              </Checkbox>
            </Col>
            <Col span={24} className="button-wrapper">
              <Button
                className="place-order-btn"
                onClick={onContinue}
                type="primary"
                loading={loading}
                disabled={!checked}
              >
                {t('checkout.place-order')}
              </Button>
            </Col>
          </Row>
        )}
      </BorderedSection>
    </div>
  );
};
export default PaymentMethod;
